import React from 'react';
import {StaticImage} from "gatsby-plugin-image"
import {useTranslation} from 'gatsby-plugin-react-i18next';

const SectionWhatCanDoComplex = () => {
  const {t} = useTranslation();
  return(
    <section className="text-gray-600 body-font overflow-hidden bg-whatcandocomplex">
      <div className="container p-5 mx-auto">
        <div className="lg:w-4/5 mx-auto flex flex-wrap">
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            <h2 className="sm-title">{t('complex.sub')}</h2>
            <h1 className="md-title">{t('complex.tag')}</h1>
            <p className="leading-relaxed mb-10">{t('complex.desc')}</p>
            <div className="flex border-t border-yellow-300 py-2">
              <span>{t('complex.difficulty')}</span>
              <span className="ml-auto">{t('complex.value1')}</span>
            </div>
            <div className="flex border-t border-b mb-6 border-yellow-300 py-2">
              <span>{t('complex.setup')}</span>
              <span className="ml-auto">{t('complex.value2')}</span>
            </div>
          </div>
          {/* <img alt="ecommerce" className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="https://dummyimage.com/400x400" /> */}
          <StaticImage className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="../images/frames/frame3.png" alt="Workflow editor" />
        </div>
      </div>
    </section>
  );
};

export default SectionWhatCanDoComplex;
