import React from 'react';
import Divider from './Divider';
import SvgCheckedLabel from './SvgCheckedLabel';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const SectionBusiness = () => {
  const {t} = useTranslation();
  return(
    <section className="text-gray-600 body-font">
      <div className="my-cont">
        <Divider title={t('biz.title')} />
        <div className="text-center mb-10">
          <p className="leading-relaxed lg:w-3/5 mx-auto text-xl text-blue-900">{t('biz.tagline')}</p>
        </div>
        <div className="flex flex-wrap -m-4">
          <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
            <h2 className="sm-title">{t('biz.hr')}</h2>
            <nav className="flex flex-col -mb-1 space-y-2.5">
              <SvgCheckedLabel title={t('biz.hr1')} checked={true} />
              <SvgCheckedLabel title={t('biz.hr2')} checked={true} />
              <SvgCheckedLabel title={t('biz.hr3')} checked={true} />
              <SvgCheckedLabel title={t('biz.hr4')} checked={true} />
            </nav>
          </div>
          <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
            <h2 className="sm-title">{t('biz.marketing')}</h2>
            <nav className="flex flex-col -mb-1 space-y-2.5">
              <SvgCheckedLabel title={t('biz.marketing1')} checked={true} />
              <SvgCheckedLabel title={t('biz.marketing2')} checked={true} />
              <SvgCheckedLabel title={t('biz.marketing3')} checked={true} />
              <SvgCheckedLabel title={t('biz.marketing4')} checked={true} />
            </nav>
          </div>
          <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
            <h2 className="sm-title">{t('biz.sales')}</h2>
            <nav className="flex flex-col -mb-1 space-y-2.5">
              <SvgCheckedLabel title={t('biz.sales1')} checked={true} />
              <SvgCheckedLabel title={t('biz.sales2')} checked={true} />
              <SvgCheckedLabel title={t('biz.sales3')} checked={true} />
              <SvgCheckedLabel title={t('biz.sales4')} checked={true} />
            </nav>
          </div>
          <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
            <h2 className="sm-title">{t('biz.support')}</h2>
            <nav className="flex flex-col -mb-1 space-y-2.5">
              <SvgCheckedLabel title={t('biz.support1')} checked={true} />
              <SvgCheckedLabel title={t('biz.support2')} checked={true} />
              <SvgCheckedLabel title={t('biz.support3')} checked={true} />
              <SvgCheckedLabel title={t('biz.support4')} checked={true} />
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionBusiness;
