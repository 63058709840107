import React from 'react';
import Divider from './Divider';
import {StaticImage} from "gatsby-plugin-image"
import {useTranslation} from 'gatsby-plugin-react-i18next';

const SectionWhatCanDoSimple = () => {
  const {t} = useTranslation();
  return(
    <section className="text-gray-600 body-font overflow-hidden bg-whatcandosimple pt-20">
      <div className="container p-5 mx-auto">
        <Divider title={t('simple.title')} />
        <div className="lg:w-4/5 mx-auto flex flex-wrap">
          {/* <img alt="ecommerce" className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="https://dummyimage.com/400x400" /> */}
          <StaticImage className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center" src="../images/frames/frame2.png" alt="Workflow editor" />
          <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
            <h2 className="sm-title">{t('simple.sub')}</h2>
            <h1 className="md-title">{t('simple.tag')}</h1>
            <p className="leading-relaxed mb-10">{t('simple.desc')}</p>
            <div className="flex border-t border-yellow-300 py-2">
              <span>{t('simple.difficulty')}</span>
              <span className="ml-auto">{t('simple.value1')}</span>
            </div>
            <div className="flex border-t border-b mb-6 border-yellow-300 py-2">
              <span>{t('simple.setup')}</span>
              <span className="ml-auto">{t('simple.value2')}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionWhatCanDoSimple;
