import React from 'react';
import {StaticImage} from "gatsby-plugin-image"
// import hero from '../images/hero.png';
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';

const SectionHero = () => {
  const {t} = useTranslation();

  return(
    <section className="text-white body-font bg-hero">
      <div className="my-cont flex md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="hero text-3xl md:text-4xl lg:text-6xl mb-5 mt-0">{t('hero.tag1')}</h1>
          <h3 className="mb-8 text-xl lg:text-2xl">{t('hero.sub')}</h3>
          <div className="flex justify-center">
            <Link className="btn" to="/inquiry">{t('hero.inquiry')}</Link>
            <button onClick={() => document.querySelector('#see-workflow-in-action').scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})}
              className="btn-1 ml-10 sm:block">{t('seeinaction.title')}</button>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          {/* <img className="object-cover object-center rounded" alt="hero" src={hero} /> */}
          <StaticImage src="../images/frames/frame1.png" alt="Workflow editor" />
        </div>
      </div>
    </section>
  )
};

export default SectionHero;
