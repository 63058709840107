import React from 'react';
import Divider from './Divider';
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';

const SectionFeature = () => {
  const {t} = useTranslation();
  return(
    <section className="text-gray-600 body-font pb-6">
      <div className="my-cont">
        <Divider title={t('feature.title')} />
        <div className="text-center mb-28">
          <p className="leading-relaxed lg:w-3/5 mx-auto text-3xl text-blue-900">{t('feature.tagline')}</p>
        </div>
        <div className="flex justify-center my-8">
          <Link className="btn text-3xl tracking-wide font-medium text-center" to="/workflow">{t('feature.button')}</Link>
        </div>
        <div className="grid lg:grid-cols-2 -m-4">
          <div className="p-4 flex">
            <div className="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
              <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-400 flex-shrink-0">
                <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 512 512"><path d="m482 321h-51v-1c0-8.284-6.716-15-15-15h-129c-16.542 0-30 13.458-30 30v58h-117c-24.813 0-45-20.187-45-45v-32c0-24.813 20.187-45 45-45h232c41.355 0 75-33.645 75-75v-32c0-41.355-33.645-75-75-75h-117v-59c0-16.542-13.458-30-30-30h-129c-8.284 0-15 6.716-15 15v2h-50c-16.804 0-31 13.738-31 30v114c0 16.262 14.196 30 31 30h50v1c0 8.284 6.716 15 15 15h129c16.542 0 30-13.458 30-30v-58h117c24.813 0 45 20.187 45 45v32c0 24.813-20.187 45-45 45-51.285 0-232.172 0-232.057 0-41.331.029-74.943 33.663-74.943 75v32c0 41.355 33.645 75 75 75h117v59c0 16.542 13.458 30 30 30h129c8.284 0 15-6.716 15-15v-2h51c16.542 0 30-13.458 30-30v-114c0-16.542-13.458-30-30-30zm-401-274v42h-51v-42zm-51 114v-42h51v42s-50.681 0-51 0zm195 16h-114c0-16.44 0-130.34 0-147h114c.018 137.463.1 147 0 147zm257 174 .007 42h-51.007v-42zm-81 131h-114v-147h114zm81-17h-51v-42h51.012z"/></svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-blue-900 text-lg title-font font-medium mb-3">{t('feature.tag1')}</h2>
                <p className="leading-relaxed text-base">{t('feature.desc1')}</p>
              </div>
            </div>
          </div>
          <div className="p-4 flex">
            <div className="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
              <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-400 flex-shrink-0">
              <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 368 368"><path d="M152,161.28c28.24-12.32,48-40.48,48-73.28c0-44.16-35.84-80-80-80S40,43.84,40,88
    c0,32.8,19.76,60.96,48,73.28V88c0-17.68,14.32-32,32-32s32,14.32,32,32V161.28z"/>
  <path d="M56,224c-22.056,0-40,17.944-40,40v96c0,4.416,3.584,8,8,8s8-3.584,8-8v-96c0-13.232,10.768-24,24-24
    s24,10.768,24,24v64c0,4.416,3.584,8,8,8s8-3.584,8-8V161.376v-0.008V88c0-13.232,10.768-24,24-24s24,10.768,24,24v73.368v0.008V200
    v64c0,4.416,3.584,8,8,8s8-3.584,8-8v-64c0-13.232,10.768-24,24-24s24,10.768,24,24v64c0,4.416,3.576,8,8,8s8-3.584,8-8v-64
    c0-13.232,10.768-24,24-24s24,10.768,24,24v64c0,4.416,3.576,8,8,8s8-3.584,8-8v-32c0-13.232,10.768-24,24-24s24,10.768,24,24v128
    c0,4.416,3.576,8,8,8s8-3.584,8-8V232c0-22.056-17.944-40-40-40c-9.04,0-17.296,3.128-24,8.208V200c0-22.056-17.944-40-40-40
    c-13.072,0-24.696,6.304-32,16.024c-7.304-9.72-18.928-16.024-32-16.024c-9,0-17.312,2.992-24,8.016v-1.712
    c29.312-14.952,48-45.112,48-78.304c0-48.52-39.48-88-88-88S32,39.48,32,88c0,33.192,18.688,63.352,48,78.304V232.2
    C73.296,227.128,65.04,224,56,224z M48,88c0-39.696,32.304-72,72-72s72,32.304,72,72c0,24.272-12.28,46.48-32,59.68V88
    c0-22.056-17.944-40-40-40S80,65.944,80,88v59.68C60.28,134.48,48,112.272,48,88z"/></svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-blue-900 text-lg title-font font-medium mb-3">{t('feature.tag2')}</h2>
                <p className="leading-relaxed text-base">{t('feature.desc2')}</p>
              </div>
            </div>
          </div>
          <div className="p-4 flex">
            <div className="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
              <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-400 flex-shrink-0">
                <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 512 512"><g><path d="m412.056 492.028h-13c-40.279 0-76.023-24.014-91.688-60h-86.992c-26.617 0-52.753-10.773-71.706-29.558-8.171-8.097-12.67-18.909-12.67-30.442s4.499-22.345 12.669-30.442c18.954-18.785 45.09-29.558 71.707-29.558h86.992c15.664-35.986 51.408-60 91.688-60h13c55.141 0 100 44.86 100 100v40c0 55.14-44.86 100-100 100zm-191.68-140c-16.154 0-32.027 6.549-43.55 17.969-.683.677-.826 1.478-.826 2.031s.144 1.354.827 2.032c11.521 11.419 27.395 17.968 43.549 17.968h101.2c9.126 0 17.094 6.177 19.369 15.015 6.816 26.487 30.713 44.985 58.11 44.985h13c33.084 0 60-26.916 60-60v-40c0-33.084-26.916-60-60-60h-13c-27.397 0-51.294 18.499-58.11 44.985-2.275 8.837-10.243 15.015-19.369 15.015zm78.624 140c0-11.046-8.954-20-20-20h-159c-44.112 0-80-35.888-80-80v-139c0-44.112 35.888-80 80-80h272c31.007 0 59.497 18.175 72.583 46.302 4.659 10.015 16.556 14.357 26.57 9.697 10.015-4.659 14.356-16.555 9.697-26.57-19.622-42.177-62.348-69.429-108.85-69.429h-272c-66.168 0-120 53.832-120 120v139c0 66.168 53.832 120 120 120h159c11.046 0 20-8.954 20-20z"/><path d="m321 105.028c-11.046 0-20-8.954-20-20 0-24.813-20.187-45-45-45s-45 20.187-45 45c0 11.046-8.954 20-20 20s-20-8.954-20-20c0-46.869 38.131-85 85-85s85 38.131 85 85c0 11.046-8.954 20-20 20zm96.055 242c-13.807 0-25 11.193-25 25s11.193 25 25 25 25-11.193 25-25-11.193-25-25-25z"/></g></svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-blue-900 text-lg title-font font-medium mb-3">{t('feature.tag3')}</h2>
                <p className="leading-relaxed text-base">{t('feature.desc3')}</p>
              </div>
            </div>
          </div>
          <div className="p-4 flex">
            <div className="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
              <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-400 flex-shrink-0">
                <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 512 512"><path d="m389.278 512.021c-6.603 0-13.294-1.317-19.765-4.014-19.137-7.976-31.503-26.645-31.503-47.562v-42.481c0-11.046 8.954-20 20-20s20 8.954 20 20v42.481c0 7.011 4.819 9.776 6.892 10.64 1.993.831 7.146 2.255 11.904-2.462l63.102-62.551c7.805-7.737 12.103-18.074 12.103-29.107s-4.298-21.371-12.103-29.107l-63.102-62.552c-4.759-4.718-9.911-3.292-11.904-2.462-2.072.864-6.892 3.629-6.892 10.64v44.481c0 11.046-8.954 20-20 20h-238c-11.028 0-20 8.972-20 20s8.972 20 20 20h32c11.046 0 20 8.954 20 20s-8.954 20-20 20h-32c-33.084 0-60-26.916-60-60s26.916-60 60-60h218v-24.481c0-20.917 12.366-39.585 31.503-47.562 19.274-8.035 40.521-3.826 55.453 10.976l63.102 62.552c15.439 15.305 23.942 35.731 23.942 57.515s-8.503 42.21-23.942 57.515l-63.102 62.552c-9.918 9.831-22.625 14.989-35.688 14.989zm-87.268-94.057c0-11.046-8.954-20-20-20h-54c-11.046 0-20 8.954-20 20s8.954 20 20 20h54c11.046 0 20-8.954 20-20zm-158.76-152.059c19.591-8.165 31.761-26.351 31.761-47.46v-22.481h217c33.084 0 60-26.916 60-60s-26.916-60-60-60h-217v-24.481c0-21.109-12.17-39.295-31.761-47.46-19.343-8.062-41.4-3.793-56.195 10.874l-63.102 62.552c-15.44 15.305-23.942 35.731-23.942 57.515s8.503 42.21 23.942 57.515l63.102 62.552c9.829 9.743 22.861 14.897 36.174 14.897 6.727 0 13.528-1.317 20.021-4.023zm-15.389-224.96c2.149.896 7.149 3.716 7.149 10.539v44.481c0 11.046 8.954 20 20 20h237c11.028 0 20 8.972 20 20s-8.972 20-20 20h-237c-11.046 0-20 8.954-20 20v42.481c0 6.822-5 9.643-7.149 10.539-2.173.906-7.754 2.49-12.646-2.361l-63.102-62.552c-7.805-7.736-12.103-18.074-12.103-29.107s4.298-21.371 12.103-29.107l63.102-62.551c2.619-2.596 5.436-3.349 7.82-3.349 2.07-.001 3.816.566 4.826.987z"/></svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-blue-900 text-lg title-font font-medium mb-3">{t('feature.tag4')}</h2>
                <p className="leading-relaxed text-base">{t('feature.desc4')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionFeature;
