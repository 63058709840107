import * as React from "react";
import {graphql} from 'gatsby';
import Seo from '../components/Seo';
import Layout from '../components/layout/Layout';
import SectionHero from '../components/SectionHero';
import SectionBusiness from '../components/SectionBusiness';
import SectionWhatCanDoSimple from '../components/SectionWhatCanDoSimple';
import SectionWhatCanDoComplex from '../components/SectionWhatCanDoComplex';
import SectionFeature from '../components/SectionFeature';
import SectionSeeInAction from '../components/SectionSeeInAction';
import SectionStatistics from "../components/SectionStatistics";
import SectionTestimonial from "../components/SectionTestimonial";
import SectionCTA from '../components/SectionCTA';

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Seo page="index" />
      <SectionHero />
      <SectionFeature />
      <SectionBusiness />
      <SectionWhatCanDoSimple />
      <SectionWhatCanDoComplex />
      <SectionStatistics />
      <SectionSeeInAction />
      <SectionTestimonial />
      <SectionCTA />
    </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "index"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;