import React from 'react';
import Divider from './Divider';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const SectionSeeInAction = () => {
  const {t} = useTranslation();
  return(
    <section className="text-gray-600 body-font bg-whatcandocomplex" id="see-workflow-in-action">
      <div className="my-cont">
        <Divider title={t('seeinaction.title')} />
        <div className="pb-10">
          <iframe src="https://player.vimeo.com/video/643890287?h=6f156cd5f9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
            frameBorder="0" allow="fullscreen; picture-in-picture" allowFullScreen 
            className="w-full h-96" title="Bi-directional incident response integration with Zendesk and Jira"></iframe>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>
    </section>
  );
};

export default SectionSeeInAction;
